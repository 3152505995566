import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Kapcsolat = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="Kapcsolat"/>

      <article>
        <header>
          <h1>Kapcsolat</h1>
        </header>
        <section>
          <p>
            A SGYAK2014B több levelezői listát is üzemeltet, de ezen listákat csak
            tagok érhetik el és használhatják.
          </p>
        </section>
      </article>

      <article>
        <header>
          <h2>Osztály lista</h2>
        </header>
        <section>
          <p>
            Email címe: <a href="mailto:sgyak2014b-osztaly@googlegroups.com">sgyak2014b-osztaly@googlegroups.com</a>
          </p>
          <p>Tagjai: a 6B osztály <strong>tanulói és tanárai</strong>, osztályfőnökkel együtt.</p>

          <p>Ha a lista tagja vagy, és rendelkezel <Link to="/google-fiok">Google
            fiókkal</Link> (és be vagy jelentkezve),
            akkor a lista archívumát (eddigi forgalmat) itt tekintheted meg:<br/>
            <a
              href="https://groups.google.com/forum/#!forum/sgyak2014b-osztaly">https://groups.google.com/forum/#!forum/sgyak2014b-osztaly</a>.
          </p>
        </section>
      </article>

      <article>
        <header>
          <h2>Szülő lista</h2>
        </header>
        <section>
          <p>
            Email címe: <a href="mailto:sgyak2014b@googlegroups.com">sgyak2014b@googlegroups.com</a>
          </p>
          <p>Tagjai: a 6B osztály tanulóinak <strong>szülei és osztályfőnöke</strong>.</p>

          <p>Ha a lista tagja vagy, és rendelkezel <Link to="/google-fiok">Google
            fiókkal</Link> (és be vagy jelentkezve),
            akkor a lista archívumát itt tekintheted meg:<br/>
            <a
              href="https://groups.google.com/forum/#!forum/sgyak2014b">https://groups.google.com/forum/#!forum/sgyak2014b</a>.
          </p>
        </section>
      </article>

      <article>
        <header>
          <h2>Sem 6B diák sem 6B szülő nem vagy</h2>
        </header>
        <section>
          <p>
            És mindenképpen kapcsolatba akarsz lépni velünk, írj emailt erre a címre: <a
            href="mailto:sgyak2014b@googlegroups.com">sgyak2014b@googlegroups.com</a>
          </p>
        </section>
      </article>

    </Layout>
  )
}

export default Kapcsolat
